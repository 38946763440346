import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import LanguageComponent from "./LanguageComponent";

const ProductSearch = ({
  products,
  dispatch,
  strings,
  currentLanguageCode,
}) => {
  const [productName, setProductName] = useState("");
  const [productList, setProductList] = useState([]);
  const { REACT_APP_LOREM_URL } = process.env;

  useEffect(() => {}, []);
  const onChange = (e) => {
    setProductName(e.target.value);
    if (e.target.value == "") {
      setProductList([]);
    } else {
      const val = e.target.value;
      const query = val.toLowerCase();
      const data = products.filter(
        (item) => item.name.toLowerCase().indexOf(query) > -1
      );
      setProductList(data);
    }
  };
  return (
    <>
      <div>
        <section className="text-center search-nav navbar-custom" id="services">
          <div className="container text-center">
            <div className="container">
              <div className="search text-center">
                <div className="row d-flex justify-content-center ">
                  <div className=" serach-nav-fix">
                    <div className="search-2 search-nav-wh">
                      <i className="bx bxs-map" />
                      <input
                        type="text"
                        placeholder={strings["product_search"]}
                        value={productName}
                        onChange={(e) => onChange(e)}
                      />
                      <button>{strings["search"]}</button>
                    </div>
                    <table className="table table-search-white">
                      <tbody>
                        {productList.length > 0
                          ? productList.slice(0, 2).map((key, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <img
                                      className="img-responsive center-block"
                                      style={{ width: "80px" }}
                                      src={
                                        key.img.length > 0
                                          ? `https://restapi.denzwater.com/uploads/${key.img}`
                                          : REACT_APP_LOREM_URL
                                      }
                                      alt="denz water technologies product"
                                    />
                                  </td>
                                  <td>
                                    <a
                                      href={`/products_details/${key.name
                                        .replaceAll(" ", "-")
                                        .toLowerCase()}/${currentLanguageCode}`}
                                    >
                                      {currentLanguageCode === "en"
                                        ? key.name
                                        : currentLanguageCode === "fr"
                                        ? key.name_fr
                                        : currentLanguageCode === "ru"
                                        ? key.name_ru
                                        : currentLanguageCode === "ar"
                                        ? key.name_ar
                                        : currentLanguageCode === "tr"
                                        ? key.name
                                        : "-"}
                                    </a>{" "}
                                  </td>
                                  <td>
                                    <a
                                      className="btn btn-default"
                                      href={`/products_details/${key.name
                                        .replaceAll(" ", "-")
                                        .toLowerCase()}/${currentLanguageCode}`}
                                    >
                                      {strings["details"]}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
        </section>{" "}
        <LanguageComponent />{" "}
      </div>
    </>
  );
};
ProductSearch.propTypes = {
  products: PropTypes.array,
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    products: state.productData.products,
  };
};

export default connect(mapStateToProps)(multilanguage(ProductSearch));
