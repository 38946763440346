import React from "react";
import { changeLanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";

const LanguageComponent = (props) => {

  const handleChangeLanguage = (languageCode) => {
    props.dispatch(changeLanguage(languageCode));
    localStorage.setItem("denz_language", languageCode);
    window.location.href = `${languageCode}`;
  };

  const generateDynamicId = (languageCode) => `language-${languageCode}`;

  return (
    <div>
      <div className="web-view">
        <nav className="navbar-center-lang ">
          <div
            className="nav navbar-nav pull-right language"
            role="radiogroup"
            aria-labelledby="language-switcher1"
          >

            {["en", "fr", "ru", "ar"].map((languageCode) => (
              <div
                className={`language__container--left language__container--${languageCode}`}
                key={languageCode}
              >
                <input
                  onClick={() => handleChangeLanguage(languageCode)}
                  onChange={(e) => {
                    props.dispatch(changeLanguage(e.target.value));
                    localStorage.setItem("denz_language", e.target.value);
                  }}
                  className="language__control"
                  type="radio"
                  id={generateDynamicId(languageCode)}
                  name={languageCode}
                  value={languageCode}
                  checked={props.currentLanguageCode === languageCode}
                />
                <label
                  className="language__label"
                  htmlFor={generateDynamicId(languageCode)}
                >
                  <span className="hidden"></span>
                </label>
              </div>
            ))}
          </div>
        </nav>
      </div>
    </div>
  );
};

LanguageComponent.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(LanguageComponent));
