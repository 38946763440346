import React, { useEffect } from "react";
import "./App.css";
import ShopProduct from "./components/ShopProduct";

import ScrollToTop from "./components/scrolltotop";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
  useParams,
  Navigate
} from "react-router-dom";

import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import Component404 from "./components/Component404";
import { languageLoader, changeLanguage } from "redux-multilanguage";
import ShopProductCategory from "./components/CategoriesProduct";
const HomeComponent = React.lazy(() => import("./components/HomeComponent"));
const AboutComponent = React.lazy(() => import("./components/AboutComponent"));
const GalleryComponent = React.lazy(() =>
  import("./components/GalleryComponent")
);
const ThirdPartyComponent = React.lazy(() =>
  import("./components/ThirdPartyComponent")
);
const ProductSingle = React.lazy(() => import("./components/ProductSingle"));

const BlogComponent = React.lazy(() => import("./components/BlogComponent"));

const SolutionsComponent = React.lazy(() =>
  import("./components/SolutionsComponent")
);
// const ShopProductCategory = React.lazy(() =>
//   import("./components/CategoriesProduct")
// );
const ContactComponent = React.lazy(() =>
  import("./components/ContactComponent")
);
const SeaWaterComponent = React.lazy(() =>
  import("./components/SeaWaterComponent")
);
const DrinkingWaterComponent = React.lazy(() =>
  import("./components/DrinkingWaterComponent")
);
const WasteWaterComponent = React.lazy(() =>
  import("./components/WasteWaterComponent")
);
const FieldsComponent = React.lazy(() =>
  import("./components/FieldsComponent")
);
const QualityComponent = React.lazy(() =>
  import("./components/QualityComponent")
);
const ValuesComponent = React.lazy(() =>
  import("./components/ValuesComponent")
);
const CertificatesComponent = React.lazy(() =>
  import("./components/CertificatesComponent")
);

const BlogSingleComponent = React.lazy(() =>
  import("./components/BlogSingleComponent")
);

const App = (props) => {
  props.dispatch(
    loadLanguages({
      languages: {
        en: require("./translation/english.json"),
        fr: require("./translation/french.json"),
        ru: require("./translation/russian.json"),
        ar: require("./translation/arabic.json"),
        tr: require("./translation/turkish.json"),
      },
    })
  );

  // changeLanguage = (e) => {
  //   localStorage.getItem(props.dispatch(changeLanguage(e.target.value)));
  // };
  useEffect(() => {
    props.dispatch(
      changeLanguage(localStorage.getItem("denz_language") ?? "en")
    );
  }, []);

  useEffect(() => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("/assets/js/jquery-3.3.1.min.js");
    loadScript("/assets/js/bootstrap.min.js");
    loadScript("/assets/js/jquery.easing.min.js");
    loadScript("/assets/js/jquery.parallax.min.js");
    loadScript("/assets/js/jquery.smartmenus.js");
    loadScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCsh1qTKHU0HysXREMrcPMtcuRBmBE7pbc"
    );

    loadScript("/assets/js/main.js");
  }, []);
  return (
    <BrowserRouter>
      {" "}
      <ScrollToTop>
        <Routes>
        <Route path="/" element={<Navigate to={`/${props.currentLanguageCode}`} />} />
          <Route exact path="/:language" element={<HomeComponent />} />
          <Route exact path="/about" element={<AboutComponent />} />
          <Route exact path="/about/:language" element={<AboutComponent />} />
          <Route exact path="/about/en" element={<AboutComponent />} />
          <Route exact path="/gallery" element={<GalleryComponent />} />
          <Route
            exact
            path="/gallery/:language"
            element={<GalleryComponent />}
          />
          <Route exact path="/contact" element={<ContactComponent />} />
          <Route
            exact
            path="/contact/:language"
            element={<ContactComponent />}
          />
          <Route exact path="/articles" element={<BlogComponent />} />
          <Route exact path="/articles/:language" element={<BlogComponent />} />
          <Route
            exact
            path="/categories_product"
            element={<ShopProductCategory />}
          />
          <Route
            exact
            path="/categories_product/:language"
            element={<ShopProductCategory />}
          />
          <Route exact path="/quality" element={<QualityComponent />} />
          <Route
            exact
            path="/quality/:language"
            element={<QualityComponent />}
          />
          <Route exact path="/values" element={<ValuesComponent />} />
          <Route exact path="/values/:language" element={<ValuesComponent />} />
          <Route exact path="/solutions" element={<SolutionsComponent />} />
          <Route
            exact
            path="/solutions/:language"
            element={<SolutionsComponent />}
          />
          <Route exact path="/fields" element={<FieldsComponent />} />
          <Route exact path="/fields/:language" element={<FieldsComponent />} />
          <Route
            exact
            path="/third_party_inspection"
            element={<ThirdPartyComponent />}
          />
          <Route
            exact
            path="/third_party_inspection/:language"
            element={<ThirdPartyComponent />}
          />
          <Route
            exact
            path="/certificates"
            element={<CertificatesComponent />}
          />
          <Route
            exact
            path="/certificates/:language"
            element={<CertificatesComponent />}
          />
          <Route exact path="/products/:categories" element={<ShopProduct />} />
          <Route
            exact
            path="/products/:categories/:language"
            element={<ShopProduct />}
          />{" "}
          <Route
            exact
            path="/products_details/:id"
            element={<ProductSingle />}
          />
          <Route
            exact
            path="/products_details/:id/:language"
            element={<ProductSingle />}
          />
          <Route
            exact
            path="/articles"
            element={<BlogSingleComponent />}
          />
          <Route
            exact
            path="/articles/:name/:language"
            element={<BlogSingleComponent />}
          />
          <Route exact path="/sea_water" element={<SeaWaterComponent />} />
          <Route
            exact
            path="/sea_water/:language"
            element={<SeaWaterComponent />}
          />
          <Route
            exact
            path="/water_supply"
            element={<DrinkingWaterComponent />}
          />
          <Route
            exact
            path="/water_supply/:language"
            element={<DrinkingWaterComponent />}
          />
          <Route exact path="/waste_water" element={<WasteWaterComponent />} />
          <Route
            exact
            path="/waste_water/:language"
            element={<WasteWaterComponent />}
          />
          <Route path="*" element={<Component404 />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};
App.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(App));
