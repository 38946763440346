import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";

const ProductSearch = ({ products, strings, currentLanguageCode }) => {
  const [productName, setProductName] = useState("");
  const [productList, setProductList] = useState([]);
  const { REACT_APP_LOREM_URL } = process.env;

  useEffect(() => {}, []);
  const onChange = (e) => {
    setProductName(e.target.value);
    if (e.target.value == "") {
      setProductList([]);
    } else {
      const val = e.target.value;
      const query = val.toLowerCase();
      const data = products.filter(
        (item) => item.name.toLowerCase().indexOf(query) > -1
      );
      setProductList(data);
    }
  };
  return (
    <>
      <div>
        <section className="text-center  p-50-bottom bg-gray" id="services">
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2">
                <img
                  src="/assets/img/denz_logo_animation.gif"
                  style={{ height: "7vh" }}
                  alt="denz water technologies "
                ></img>

                <p>{strings["footer_search_text"]}</p>
              </div>
            </div>
            <div className="container">
              <div className="search">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-8">
                    <div className="search-2">
                      <i className="bx bxs-map" />
                      <input
                        type="text"
                        placeholder={strings["product_search"]}
                        value={productName}
                        onChange={(e) => onChange(e)}
                      />
                      <button>{strings["search"]}</button>
                    </div>
                    <table className="table mt-5 table-search">
                      <tbody>
                        {productList.length > 0
                          ? productList.slice(0, 3).map((key, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <img
                                      className="img-responsive center-block"
                                      style={{ width: "80px" }}
                                      src={
                                        key.img.length > 0
                                          ? `https://restapi.denzwater.com/uploads/${key.img}`
                                          : REACT_APP_LOREM_URL
                                      }
                                      alt="denz water technologies product"
                                    />
                                  </td>
                                  <td>
                                    <a
                                      href={`/products_details/${key.name
                                        .replaceAll(" ", "-")
                                        .toLowerCase()}/${currentLanguageCode}`}
                                    >
                                      {currentLanguageCode === "en"
                                        ? key.name
                                        : currentLanguageCode === "fr"
                                        ? key.name_fr
                                        : currentLanguageCode === "ru"
                                        ? key.name_ru
                                        : currentLanguageCode === "ar"
                                        ? key.name_ar
                                        : currentLanguageCode === "tr"
                                        ? key.name
                                        : "-"}
                                    </a>{" "}
                                  </td>
                                  <td>
                                    <a
                                      className="btn btn-default"
                                      href={`/products_details/${key.name
                                        .replaceAll(" ", "-")
                                        .toLowerCase()}/${currentLanguageCode}`}
                                    >
                                      {strings["details"]}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                    <span className="text-center">
                      {productList.length > 0 ? (
                        <>
                          {productList.length} {strings["product_found"]}
                        </>
                      ) : productName ? (
                        <p>{strings["not_product_found"]}</p>
                      ) : null}
                    </span>
                    <div className="d-flex justify-content-center flex-column new_main">
                      {" "}
                      <p>{strings["Lets"]}</p>
                      <div className="new_button">
                        {" "}
                        <a
                          target="_blank"
                          href={`/contact/${currentLanguageCode}`}
                        >
                          {" "}
                          <button>{strings["contact"]}</button>
                        </a>{" "}
                      </div>
                    </div>
                    <div className="col-lg-12 product-center ">
                      <div className="row  text-center">
                        <div className="col-md-12">
                          <div className="mt-5 ">
                            <div className=" ">
                              <h4>
                                <div className="d-flex justify-content-center">
                                  <img
                                    src="/assets/img/5.gif"
                                    style={{ height: "7vh" }}
                                    alt="denz water technologies "
                                  ></img>
                                </div>
                                <h5
                                  className="new_button"
                                  style={{ margin: "0" }}
                                >
                                  <button>
                                    {" "}
                                    <a
                                      href={`/categories_product/${currentLanguageCode}`}
                                      target={"_blank"}
                                    >
                                      {strings["product_categories"]}
                                    </a>{" "}
                                  </button>
                                </h5>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
ProductSearch.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(ProductSearch));
