import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/rootReducer";
import blogs from "./data/blog.json";
import { product } from "./data/product.js";
import { fetchProducts } from "./redux/actions/productAction";
import { fetchBlogs } from "./redux/actions/blogAction";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import axios from "axios";

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const fetchData = async () => {
  try {
    const [blogsResponse, productsResponse] = await Promise.all([
      axios.get("https://restapi.denzwater.com/blog"),
      axios.get("https://restapi.denzwater.com/productdenz"),
    ]);

    store.dispatch(fetchBlogs(blogsResponse.data));
    store.dispatch(fetchProducts(productsResponse.data));
  } catch (error) {
    console.error("Data fetching error:", error);
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));

fetchData();


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
