import productReducer from "./productReducer";
import blogReducer from "./blogReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";

const rootReducer = combineReducers({
  productData: productReducer,
  blogData: blogReducer,
  multilanguage: createMultilanguageReducer(),
});

export default rootReducer;
