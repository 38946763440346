import React, { useEffect, useState } from "react";
import ProductSearch from "../components/ProductSearch";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";

const Footer = ({ products, strings, currentLanguageCode }) => {
  const [collection, setCollection] = useState([]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    let arr = [];
    let seen = new Set(),
      result = products.filter(
        ({ categories }) => !seen.has(categories) && seen.add(categories)
      ),
      categories = result.map((key) => {
        const obj = {
          checked: false,
          categories: key.categories,
          categories_fr: key.categories_fr,
          categories_ru: key.categories_ru,
          categories_ar: key.categories_ar,
          categories_img: key.categories_img,
          count: 0,
        };
        arr.push(obj);
      });

    products.forEach((product) => {
      const categoryIndex = arr.findIndex(
        (category) => category.categories === product.categories
      );
      if (categoryIndex !== -1) {
        arr[categoryIndex].count++;
      }
    });

    setCategories(arr);
    setCollection(arr);
  }, [products]);
  function chunkArray(array, chunkSize) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  }

  return (
    <div>
      {" "}
      <section className="section-small bg-gray">
      
        <ProductSearch products={products} />
      </section>
      <section className="section-footer bg-gray2 footer">
        <div className="container text-center text-md-start mt-5">
          {/* Grid row */}
          <div className="container mt-3">
            <div className="row">
              {" "}
              <div className="col-md-12 col-lg-3 col-xl-3 mx-auto mb-4">
                {/* Content */}
                <h6 className="  text-uppercase fw-bold">
                  Denz Water Technologies
                </h6>

                <p>{strings["footer_text"]}</p>
                <img
                  className="footer_logo"
                  src="/assets/img/denz_logo.svg"
                  alt="denz water technologies "
                ></img>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-12 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="  text-uppercase fw-bold">{strings["about"]}</h6>

                <p>
                  <a href="/about" className="text-white">
                    {strings["about_us_2"]}
                  </a>
                </p>

                <p>
                  <a href="/solutions" className="text-white">
                    {strings["solutions_2"]}
                  </a>
                </p>
                <p>
                  <a href="/fields" className="text-white">
                    {strings["fields_2"]}
                  </a>
                </p>
                <p>
                  <a href="/values" className="text-white">
                    {strings["values_2"]}
                  </a>
                </p>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-12 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="  text-uppercase fw-bold">
                  {strings["qualitly"]}
                </h6>

                <p>
                  <a href="quality" className="text-white">
                    {strings["qualitly"]}
                  </a>
                </p>
                <p>
                  <a href="certificates" className="text-white">
                    {strings["certificates_2"]}
                  </a>
                </p>
                <p>
                  <a href="third_party_inspection" className="text-white">
                    {strings["third_party_inception_2"]}
                  </a>
                </p>
              </div>
              <div className="col-md-12 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="  text-uppercase fw-bold">
                  {" "}
                  {strings["induestries"]}
                </h6>

                <p>
                  <a href="/water_supply" className="text-white">
                    {strings["home_text_1_1"]}
                  </a>
                </p>
                <p>
                  <a href="sea_water" className="text-white">
                    {strings["sea_water_2"]}
                  </a>
                </p>
                <p>
                  <a href="waste_water" className="text-white">
                    {strings["waste_water_2"]}
                  </a>
                </p>
                <br />
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-12 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                {/* Links */}
                <h6 className="  text-uppercase fw-bold">
                  {" "}
                  {strings["contact_2"]}
                </h6>

                <p>
                  <i className="fas fa-home mr-3" /> Mustafa Kemal Mahallesi,
                  Tepe Prime, C Blok, No: 71, 06530 Çankaya, Ankara, Türkiye
                </p>
                <p>
                  <i className="fas fa-envelope mr-3" />{" "}
                  <a href="mailto:sales@denzwater.com">sales@denzwater.com</a>
                </p>

                <p>
                  <i className="fas fa-phone mr-3" />{" "}
                  <a href="tel:903122860906">90 312 286 0906</a>
                </p>
                <p>
                  <i className="fas fa-phone mr-3" /> <a> +90 850 309 7907</a>
                </p>

                <div className="col-2 ">
                  <ul className="list-inline no-pad">
                    <li>
                      <a
                        href="https://www.instagram.com/denzwater/?igshid=NTdlMDg3MTY%3D"
                        target="_blank"
                        aria-label="instagram"
                        rel="noreferrer"
                      >
                        <i className="fab fa-instagram fa-fw fa-lg" />
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.linkedin.com/company/denzwater/"
                        target="_blank"
                        aria-label="linkedin"
                        rel="noreferrer"
                      >
                        <i className="fab fab fa-linkedin-in fa-fw fa-lg" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase fw-bold ">
                  {strings["categories"]}
                </h6>

                {chunkArray(collection, 5).map((chunk, index) => (
                  <div className="row footer-categories" key={index}>
                    {chunk.map((item, itemIndex) => (
                      <div className="col-12 mb-3" key={itemIndex}>
                        <a
                          href={`/products/${
                            item.categories
                              ? item.categories
                                  .replaceAll(" ", "-")
                                  .toLowerCase()
                              : ""
                          }/${currentLanguageCode}`}
                        >
                          <p className="text-center">
                            {currentLanguageCode === "en"
                              ? item.categories.slice(9)
                              : currentLanguageCode === "fr"
                              ? item.categories_fr
                              : currentLanguageCode === "ru"
                              ? item.categories_ru
                              : currentLanguageCode === "ar"
                              ? item.categories_ar
                              : currentLanguageCode === "tr"
                              ? item.categories.slice(9)
                              : "-"}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Grid row */}
        </div>{" "}
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-4 ">
            <h5>
              © 2022 All Rights Reserved. <i className="fa fa-heart fa-fw" />{" "}
              <a href="https://osicrew.com" target={"_blank"}>
                {" "}
                OSICrew
              </a>
            </h5>
          </div>
        </div>
      </section>
    </div>
  );
};

Footer.propTypes = {
  products: PropTypes.array,
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    products: state.productData.products,
  };
};

export default connect(mapStateToProps)(multilanguage(Footer));
