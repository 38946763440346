import React, { useEffect } from "react";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { changeLanguage } from "redux-multilanguage";
import { useLocation, useParams, Link } from "react-router-dom";

const Component404 = (props) => {
  // const { state } = useLocation();
  let { language } = useParams();
  useEffect(() => {
    if (language) {
      props.dispatch(changeLanguage(language));
      localStorage.setItem("denz_language", language);
    } else {
      changeLanguage(localStorage.getItem("denz_language") ?? "en");
    }
  }, []);
  useEffect(() => {
    document.title = "404 | DENZ ";
    // props.dispatch(state.language);
  }, []);
  return (
    <div>
      {" "}
      <header className="intro introhalf">
        <div className="overlay2" />

        <div className="intro-body">
          <img
            src="/assets/img/denz_logo_beyaz-01.svg"
            style={{ width: "20vh" }}
          ></img>
          <h1
            className="wow fadeInUp"
            data-wow-duration="2s"
            data-wow-delay=".2s"
          >
            DENZ Water Technologies
          </h1>
        </div>
      </header>
      <section className="section-404" id="contact2">
        <div className="container text-center">
          <div className="row ">
            <div className="col-md-12 ">
              <img style={{ height: "23vh" }} src="/assets/img/yapim.gif"></img>
              <h2 className="mt-5">404</h2>
              <h2>page not found</h2> <br />
            </div>
          </div>
        </div>
      </section>
      <div className="row d-flex justify-content-center mt-5">
        <div className="col-4 ">
          <h5>
            © 2022 All Rights Reserved. <i className="fa fa-heart fa-fw" />{" "}
            <a href="https://osicrew.com" target={"_blank"}>
              {" "}
              OSICrew
            </a>
          </h5>
        </div>
      </div>
    </div>
  );
};
Component404.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(Component404));
