import React, { useState, useEffect } from "react";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { multilanguage} from "redux-multilanguage";
import { useParams, Link } from "react-router-dom";
import { changeLanguage } from "redux-multilanguage";
import { Helmet } from "react-helmet";

const ProductPage = ({ products, strings, currentLanguageCode, dispatch }) => {
  const [collection, setCollection] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchName, setSearchName] = useState("");
  const { REACT_APP_LOREM_URL } = process.env;

  let { language } = useParams();
  useEffect(() => {
    if (language) {
      dispatch(changeLanguage(language));
      localStorage.setItem("denz_language", language);
    } else {
      dispatch(changeLanguage(localStorage.getItem("denz_language") ?? "en"));
    }
  }, []);

  useEffect(() => {
    let arr = [];
    let seen = new Set(),
      result = products.filter(
        ({ categories }) => !seen.has(categories) && seen.add(categories)
      ),
      categories = result.map((key) => {
        const obj = {
          checked: false,
          categories: key.categories,
          categories_fr: key.categories_fr,
          categories_ru: key.categories_ru,
          categories_ar: key.categories_ar,
          categories_img: key.categories_img,
          count: 0,
        };
        arr.push(obj);
      });

    products.forEach((product) => {
      const categoryIndex = arr.findIndex(
        (category) => category.categories === product.categories
      );
      if (categoryIndex !== -1) {
        arr[categoryIndex].count++;
      }
    });

    setCategories(arr);
    setCollection(arr);
  }, [products]);

  const onChange = (e) => {
    if (e.target.value == "") {
      setCollection(categories);
    } else {
      const val = e.target.value;
      const query = val.toLowerCase();
      const data = categories.filter(
        (item) => item.categories.toLowerCase().indexOf(query) > -1
      );
      setCollection(data);
    }
    setSearchName(e.target.value);
  };

  return (
    <>
      {collection.map((key, index) => (
        <React.Fragment key={index}>
          <Helmet>
            <title>{strings["product_categories"]} | DENZ</title>
            <meta property="og:type" content="article" />

            <meta
              property="og:url"
              content={`https://www.denzwater.com/categories_product/${currentLanguageCode}`}
            />
            <meta
              name="description"
              content="We manufacture and export valves, fittings, flow control and filtration equipments for water supply, wastewater, sea water, irrigation and HVAC Sectors."
            />
            <link
              rel="canonical"
              href={`https://www.denzwater.com/categories_product/${currentLanguageCode}`}
            />
            <link rel="icon" href="/favicon.ico" />
          </Helmet>
        </React.Fragment>
      ))}
      <Header />

      <header className="intro introhalf">
        <div className="overlay2"></div>
        <div className="intro-body">
          <h1
            className="wow fadeInUp"
            data-wow-duration="2s"
            data-wow-delay=".2s"
          >
            {strings["products_2"]}
          </h1>
        </div>
      </header>

      <section className="section-small" id="shop">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row grid-pad">
                <div className="d-flex justify-content-center ">
                  <h2>{strings["categories"]}</h2>{" "}
                </div>{" "}
                <div className="col-md-8 text-center">
                  <div className="container-fluid">
                    <div className="row">
                      {products.length > 0 ? (
                        collection.map((key, index) => {
                          console.log(products.length);
                          return (
                            <span key={index}>
                              {index % 3 === 0 && index !== 0 ? (
                                <>
                                  <div className="clearfix"></div>
                                </>
                              ) : null}
                              <div className="col-sm-4">
                                <div className="shop-item">
                                  <Link
                                    to={
                                      "/products/" +
                                      key.categories
                                        .replaceAll(" ", "-")
                                        .toLowerCase() +
                                      `/${currentLanguageCode}`
                                    }
                                    state={{ state: key.categories }}
                                  >
                                    {key.categories_img ? (
                                      <img
                                        className="img-responsive center-block aw-zoom"
                                        src={`https://restapi.denzwater.com/uploads/${key.categories_img}`}
                                        alt={
                                          currentLanguageCode === "en"
                                            ? key.categories
                                            : currentLanguageCode === "fr"
                                            ? key.categories_fr
                                            : currentLanguageCode === "ru"
                                            ? key.categories_ru
                                            : currentLanguageCode === "ar"
                                            ? key.categories_ar
                                            : currentLanguageCode === "tr"
                                            ? key.categories
                                            : "-"
                                        }
                                      />
                                    ) : (
                                      <img
                                        className="img-responsive center-block aw-zoom"
                                        src={REACT_APP_LOREM_URL}
                                        alt={
                                          currentLanguageCode === "en"
                                            ? key.categories
                                            : currentLanguageCode === "fr"
                                            ? key.categories_fr
                                            : currentLanguageCode === "ru"
                                            ? key.categories_ru
                                            : currentLanguageCode === "ar"
                                            ? key.categories_ar
                                            : currentLanguageCode === "tr"
                                            ? key.categories
                                            : "-"
                                        }
                                      />
                                    )}
                                  </Link>
                                </div>
                                <a
                                  className="d-flex justify-content-center"
                                  href={`/products/${key.categories
                                    .replaceAll(" ", "-")
                                    .toLowerCase()}/${currentLanguageCode}`}
                                >
                                  {" "}
                                  <h2 style={{fontSize:"1.5rem"}}>
                                    {" "}
                                    {currentLanguageCode === "en"
                                      ? key.categories
                                      : currentLanguageCode === "fr"
                                      ? key.categories_fr
                                      : currentLanguageCode === "ru"
                                      ? key.categories_ru
                                      : currentLanguageCode === "ar"
                                      ? key.categories_ar
                                      : currentLanguageCode === "tr"
                                      ? key.categories
                                      : "-"}
                                  </h2>
                                  <h5 style={{ color: "#7acae8" }}>
                                    &nbsp;({key.count})
                                  </h5>
                                </a>
                              </div>
                            </span>
                          );
                        })
                      ) : (
                        <tr>
                          <th scope="row" colSpan={8} className="text-center">
                            loading
                          </th>
                        </tr>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-md-offset-1">
                  <form className="form-inline subscribe-form">
                    <div className="input-group input-group-lg">
                      <input
                        className="form-control"
                        type="search"
                        name="search"
                        placeholder="Search..."
                        value={searchName}
                        onChange={(e) => onChange(e)}
                      />
                      <span className="input-group-btn ml-5">
                        <button
                          className="btn btn-info btn-product"
                          onClick={(e) => {
                            e.preventDefault();
                            if (searchName === "") {
                              setCollection(categories);
                            } else {
                              const val = searchName;
                              const query = val.toLowerCase();
                              const data = categories.filter(
                                (item) =>
                                  item.categories.toLowerCase().indexOf(query) >
                                  -1
                              );
                              setCollection(data);
                            }
                          }}
                        >
                          {" "}
                          <i className="fa fa-search fa-lg" />
                        </button>
                      </span>
                    </div>
                  </form>
                  <hr />
                  <h4>{strings["categories"]}</h4>
                  <div className="mobile_">
                    <ul className="">
                      {categories.map((key, index) => {
                        return (
                          <li key={index}>
                            <Link
                              to={
                                "/products/" +
                                key.categories
                                  .replaceAll(" ", "-")
                                  .toLowerCase() +
                                `/${currentLanguageCode}`
                              }
                              state={{ state: key.categories }}
                            >
                              {" "}
                              {currentLanguageCode === "en"
                                ? key.categories.slice(9)
                                : currentLanguageCode === "fr"
                                ? key.categories_fr
                                : currentLanguageCode === "ru"
                                ? key.categories_ru
                                : currentLanguageCode === "ar"
                                ? key.categories_ar
                                : currentLanguageCode === "tr"
                                ? key.categories.slice(9)
                                : "-"}
                            </Link>
                          </li>
                        );
                      })}
                      <br />
                    </ul>
                  </div>

                  <hr />

                  <div className="row pricing text-center">
                    <div className="col-md-12 ">
                      <div className="panel panel-default ">
                        <img
                          src="/assets/img/denz_logo.svg"
                          style={{ height: "6vh", margin: "20px" }}
                          alt="denz water technologies valves and fittings"
                        ></img>
                        <div className="panel-heading ">
                          <h4>
                            <a
                              href="/assets/img/pdf/denz_brochure.pdf"
                              target={"_blank"}
                            >
                              {strings["product_portfolio"]}
                            </a>{" "}
                            <div className="d-flex justify-content-center">
                              <img
                                src="/assets/img/5.gif"
                                style={{ height: "7vh" }}
                                alt="denz water technologies valves and fittings"
                              ></img>
                            </div>
                            <h5 style={{ margin: "0" }}>
                              <a
                                href="/assets/img/pdf/denz_brochure.pdf"
                                target={"_blank"}
                              >
                                {
                                  strings[
                                    "Download_Our_Latest_Product_Brochure"
                                  ]
                                }
                              </a>{" "}
                            </h5>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

ProductPage.propTypes = {
  products: PropTypes.array,
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    products: state.productData.products,
  };
};

export default connect(mapStateToProps)(multilanguage(ProductPage));
