import React, { useEffect, useRef, useState } from "react";
import ProductSearch from "../components/ProductSearchNav";
import $ from "jquery";
import { NavLink, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { changeLanguage } from "redux-multilanguage";

import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
  useParams,
} from "react-router-dom";

const Header2 = (props) => {
  const [open, setOpen] = React.useState(false);
  const form = useRef();

  const [value, setValue] = useState();

  function onChange(value) {
    setValue(value);
  }
  const sendEmail = (e) => {
    e.preventDefault();

    if (value) {
      emailjs
        .sendForm(
          "service_cgx1i5m",
          "template_idsmqhf",
          form.current,
          "gmIuWdDHw9Iofgfzm"
        )
        .then((result) => {
          if (result.status === 200) {
            toast.success("İletişim Formunuz Başarıyla Gönderildi");
            window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'formsubmission',
      "formname": 'quoteform'
    });
          } else {
            toast.error("İletişim Formunuz Gönderilemedi");
          }
        })
        .catch((err) => {
          toast.error("İletişim Formunuz Gönderilemedi");
          // console.log(err);
        });
    } else {
      toast.error("Lütfen tüm alanları doldurunuz");
    }
  };
  useEffect(() => {
    const showBtn = document.querySelector(".btn-bars"),
      closeBtn = document.querySelector(".btn-close"),
      navMenu = document.querySelector(".navbar-collapse-q");
    showBtn.addEventListener("click", () => {
      navMenu.classList.add("showMenu");
    });
    closeBtn.addEventListener("click", () => {
      navMenu.classList.remove("showMenu");
    });

    const showBtn2 = document.querySelector(".btn-bars-w"),
      closeBtn2 = document.querySelector(".btn-close-w"),
      navMenu2 = document.querySelector(".navbar-collapse-q-w");
    showBtn2.addEventListener("click", () => {
      navMenu2.classList.add("showMenu-w");
    });
    closeBtn2.addEventListener("click", () => {
      navMenu2.classList.remove("showMenu-w");
    });
  }, []);
  return (
    <div>
      {" "}
      <nav className="navbar navbar-Concept navbar-center navbar-custom navbar-fixed-top nav-height bg-light">
        <ProductSearch />
        <ToastContainer />

        <button
          className="navbar-toggle"
          type="button"
          // data-toggle="collapse"
          // data-target=".navbar-main-collapse"
          onClick={(e) => {
            if (
              $(".mini-nav").hasClass(
                "collapsing navbar-collapse navbar-main-collapse mini-nav"
              )
            ) {
              //menu acılıyor
              $(".mini-nav").removeClass("collapsing");
              $(".mini-nav").addClass(
                "navbar-collapse navbar-main-collapse mini-nav collapse in thema-color"
              );
              $(".mb-logo").addClass("d-none-logo");

              $(".mobil-view").addClass("thema-color");
              $(".navbar-brand").addClass("d-none-logo");
              $(".navbar-Concept").addClass("thema-color");
              $(".navbar-Concept").addClass("mini-100vh");
            } else if (
              $(".mini-nav").hasClass(
                "navbar-collapse navbar-main-collapse mini-nav collapse in"
              )
            ) {
              //menu kapatıyor
              $(".mobil-view").removeClass("thema-color");
              $(".mini-nav").removeClass("collapse in thema-color");
              $(".navbar-Concept").removeClass("thema-color");
              $(".navbar-brand").removeClass("d-none-logo");
              $(".mini-nav").addClass(
                "collapsing navbar-collapse navbar-main-collapse mini-nav"
              );
              $(".mb-logo").removeClass("d-none-logo");
              $(".navbar-Concept").removeClass("mini-100vh");
            } else if (
              //acılıyor
              $(".mini-nav").hasClass(
                "navbar-collapse navbar-main-collapse mini-nav collapsing"
              )
            ) {
              $(".mb-logo").addClass("d-none-logo");
              $(".navbar-Concept").addClass("thema-color");
              $(".mobil-view").addClass("thema-color");
              $(".mini-nav").removeClass("collapsing");
              $(".navbar-brand").addClass("d-none-logo");
              $(".mini-nav").addClass(
                "navbar-collapse navbar-main-collapse mini-nav collapse in thema-color"
              );
              $(".navbar-Concept").addClass("mini-100vh");
            }
          }}
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
        <div className="container mt-5-nav mobil-view">
          <div className="navbar-header mb-logo">
            <a className="navbar-brand page-scroll" href="/">
              {/* Text or Image logo*/}
              <div className="logobig">
                <span className="logo">
                  {" "}
                  <img
                    src="/assets/img/denz_logo_beyaz-01.svg"
                    style={{ width: "20vh" }}
                    alt="denz water technologies "
                  />
                </span>
              </div>
              <span className="logodark">
                {" "}
                <img
                  src="/assets/img/denz_logo_final-01.svg"
                  style={{ width: "20vh" }}
                  alt="denz water technologies "
                />
              </span>
            </a>
          </div>
          {/* Collect the nav links, forms, and other content for toggling*/}
          <div className="collapsing navbar-collapse navbar-main-collapse mini-nav">
            <ul className="nav navbar-nav">
              {/* Hidden li included to remove active class from about link when scrolled up past about section*/}
              <li className="hidden">
                <a href="/" />
              </li>
              <li>
                <a href="/">{props.strings["home_2"]}</a>
              </li>
              <li>
                <a href={`/about/${props.currentLanguageCode}`}>
                  {props.strings["about_2"]} <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href={`/about/${props.currentLanguageCode}`}>
                      {props.strings["about_us_2"]}
                    </a>
                  </li>

                  <li>
                    <a href={`/solutions/${props.currentLanguageCode}`}>
                      {props.strings["solutions_2"]}
                    </a>
                  </li>
                  <li>
                    <a href={`/fields/${props.currentLanguageCode}`}>
                      {props.strings["fields_2"]}
                    </a>
                  </li>
                  <li>
                    <a href={`/values/${props.currentLanguageCode}`}>
                      {props.strings["values_2"]}
                    </a>
                  </li>
                  <li>
                    <a href={`/gallery/${props.currentLanguageCode}`}>
                      {props.strings["gallery_2"]}
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a href={`/quality/${props.currentLanguageCode}`}>
                  {props.strings["qualitly"]} <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href={`/quality/${props.currentLanguageCode}`}>
                      {" "}
                      {props.strings["qualitly"]}
                    </a>
                  </li>
                  <li>
                    <a href={`/certificates/${props.currentLanguageCode}`}>
                      {props.strings["certificates_2"]}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/third_party_inspection/${props.currentLanguageCode}`}
                    >
                      {" "}
                      {props.strings["third_party_inception_2"]}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href={`/articles/${props.currentLanguageCode}`}>
                  {props.strings["articles_2"]}
                  <span className="caret" />
                </a>
              </li>
              <li>
                <a href={`/sea_water/${props.currentLanguageCode}`}>
                  {props.strings["induestries_2"]}
                  <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href={`/water_supply/${props.currentLanguageCode}`}>
                      {" "}
                      {props.strings["home_text_1_1"]}
                    </a>
                  </li>
                  <li>
                    <a href={`/sea_water/${props.currentLanguageCode}`}>
                      {props.strings["sea_water_2"]}
                    </a>
                  </li>
                  <li>
                    <a href={`/waste_water/${props.currentLanguageCode}`}>
                      {props.strings["waste_water_2"]}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="nav navbar-nav pull-right">
              {" "}
              <li>
                <a href={`/categories_product/${props.currentLanguageCode}`}>
                  {props.strings["products_2"]} <span className="caret" />
                </a>
              </li>
              <li>
                <a href={`/contact/${props.currentLanguageCode}`}>
                  {props.strings["contact_2"]}
                  <span className="caret" />
                </a>
              </li>
              <li>
                <nav className="navbar-q">
                  <button type="button" className="btn-bars">
                    <a href="#" style={{ borderBottom: "none" }}>
                      {props.strings["get_quote"]}
                    </a>
                  </button>
                  <div className="navbar-collapse-q">
                    <span className="btn-close ">
                      <img
                        src="/assets/img/close.png"
                        alt="denz water technologies "
                      ></img>
                    </span>
                    <div className="col-md-12 text-center">
                      {/* Map Section*/}
                      <img
                        src="/assets/img/denz_logo_animation.gif"
                        style={{ height: "5vh" }}
                        alt="denz water technologies "
                      />

                      <p className="lets-mobil">{props.strings["Lets"]}</p>
                      <form
                        ref={form}
                        onSubmit={sendEmail}
                        id="contactForm"
                        name="sentMessage"
                        noValidate
                      >
                        <div className="col-lg-12 control-group">
                          <div className="form-group floating-label-form-group controls">
                            <label
                              className="sr-only control-label"
                              htmlFor="name"
                            >
                              {props.strings["name"]}
                            </label>
                            <input
                              className="form-control input-lg-2"
                              id="name"
                              name="name"
                              type="text"
                              placeholder={props.strings["name"]}
                              required
                              data-validation-required-message="Please enter name"
                            />
                            <span className="help-block text-danger" />
                          </div>
                        </div>
                        <div className="col-lg-12 control-group">
                          <div className="form-group floating-label-form-group controls">
                            <label
                              className="sr-only control-label"
                              htmlFor="tel"
                            >
                              {props.strings["tel"]}
                            </label>
                            <input
                              className="form-control input-lg-2"
                              id="tel"
                              name="tel"
                              type="tel"
                              placeholder={props.strings["tel"]}
                              required
                              data-validation-required-message="Please enter telephone"
                            />
                            <span className="help-block text-danger" />
                          </div>
                        </div>
                        <div className="col-lg-12 control-group">
                          <div className="form-group floating-label-form-group controls">
                            <label
                              className="sr-only control-label"
                              htmlFor="company"
                            >
                              {props.strings["company"]}
                            </label>
                            <input
                              className="form-control input-lg-2"
                              id="company"
                              type="text"
                              name="company"
                              placeholder={props.strings["company"]}
                              required
                              data-validation-required-message="Please enter company"
                            />
                            <span className="help-block text-danger" />
                          </div>
                        </div>
                        <div className="col-lg-12 control-group">
                          <div className="form-group floating-label-form-group controls">
                            <label
                              className="sr-only control-label"
                              htmlFor="email"
                            >
                              {props.strings["email"]}
                            </label>
                            <input
                              className="form-control input-lg-2"
                              id="email"
                              type="email"
                              name="email"
                              placeholder={props.strings["email"]}
                              required
                              data-validation-required-message="Please enter email"
                            />
                            <span className="help-block text-danger" />
                          </div>
                        </div>
                        <div className="control-group">
                          <div className="form-group floating-label-form-group controls">
                            <label
                              className="sr-only control-label"
                              htmlFor="message"
                            >
                              {props.strings["message"]}
                            </label>
                            <textarea
                              className="form-control input-lg-2"
                              id="message"
                              rows={4}
                              name="message"
                              placeholder={props.strings["message"]}
                              required
                              data-validation-required-message="Please enter a message."
                              aria-invalid="false"
                              defaultValue={""}
                            />
                            <span className="help-block text-danger" />
                          </div>
                        </div>
                        <div id="success" />{" "}
                        <div className="d-flex justify-content-center">
                          <ReCAPTCHA
                            sitekey="6LdcOPQjAAAAANfz-i0yOYwK9oXckVoC1IPOvanm"
                            onChange={onChange}
                            theme="light"
                            size="compact"
                          />{" "}
                        </div>
                        <div className="d-flex justify-content-center">
                          <button className="btn btn-dark btn-lg" type="submit">
                            {props.strings["send"]}
                          </button>
                        </div>
                        <ul className="list-inline no-pad  d-flex justify-content-center">
                          <li>
                            <a
                              href="https://www.instagram.com/denzwater/?igshid=NTdlMDg3MTY%3D"
                              target="_blank"
                              aria-label="denz instagram"
                            >
                              <i className="fab fa-instagram fa-fw fa-lg" />
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://www.linkedin.com/company/denzwater/"
                              target="_blank"
                              aria-label="denz linkedin"
                            >
                              <i className="fab fab fa-linkedin-in fa-fw fa-lg" />
                            </a>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                </nav>
              </li>
          <li style={{padding:"1vh"}}>
            {/* <div className="language__container--left language__container--tr">
                <input
                  onChange={(e) => {
                    props.dispatch(changeLanguage(e.target.value));
                  }}
                  className="language__control"
                  type="radio"
                  id="language1-5"
                  name="en"
                  value={"en"}
                  checked={props.currentLanguageCode == "tr" ? true : false}
                />
                <label className="language__label" htmlFor="language1-5">
                  <span className="hidden"></span>
                </label>
              </div> */}
              <div className="language__container--left language__container--en">
                <input
                  onChange={(e) => {
                    props.dispatch(changeLanguage(e.target.value));
                  }}
                  className="language__control"
                  type="radio"
                  id="language1-1"
                  name="en"
                  value={"en"}
                  checked={props.currentLanguageCode == "en" ? true : false}
                />
                <label className="language__label" htmlFor="language1-1">
                  <span className="hidden"></span>
                </label>
              </div>
              <div className="language__container--left language__container--fr">
                <input
                  onChange={(e) => {
                    props.dispatch(changeLanguage(e.target.value));
                  }}
                  className="language__control"
                  type="radio"
                  id="language1-2"
                  name="fr"
                  value={"fr"}
                  checked={props.currentLanguageCode == "fr" ? true : false}
                />
                <label className="language__label" htmlFor="language1-2">
                  <span className="hidden"></span>
                </label>
              </div>
              <div className="language__container--left language__container--ru">
                <input
                  onChange={(e) => {
                    props.dispatch(changeLanguage(e.target.value));
                  }}
                  className="language__control"
                  type="radio"
                  id="language1-3"
                  name="ru"
                  value={"ru"}
                  checked={props.currentLanguageCode == "ru" ? true : false}
                />
                <label className="language__label" htmlFor="language1-3">
                  <span className="hidden"></span>
                </label>
              </div>
              {/* <div className="language__container--left language__container--ru">
                <nav className="pad">
                  <Link to={"/under_construction"} state={{ language: "ru" }}>
                    <img
                      src="/assets/img/russia.png"
                      alt="denz water technologies "
                    ></img>
                  </Link>
                </nav>
              </div> */}
              <div className="language__container--left language__container--ar">
                <input
                  onChange={(e) => {
                    props.dispatch(changeLanguage(e.target.value));
                  }}
                  className="language__control"
                  type="radio"
                  id="language1-4"
                  name="ar"
                  value={"ar"}
                  checked={props.currentLanguageCode == "ar" ? true : false}
                />
                <label className="language__label" htmlFor="language1-4">
                  <span className="hidden"></span>
                </label>
              </div></li>
              
            </ul>
          </div>
        </div>
        <div className="container mt-5-nav web-view">
          <div className="navbar-header">
            <a className="navbar-brand page-scroll" href="/">
              {/* Text or Image logo*/}
              <div className="logobig">
                <span className="logo">
                  <img
                    src="/assets/img/denz_logo_beyaz-01.svg"
                    style={{ width: "20vh" }}
                    alt="denz water technologies "
                  />
                </span>
              </div>
              <span className="logodark">
                {" "}
                <img
                  src="/assets/img/denz_logo_final-01.svg"
                  style={{ width: "20vh" }}
                  alt="denz water technologies "
                />
              </span>
            </a>
          </div>
          {/* Collect the nav links, forms, and other content for toggling*/}
          <div className="collapse navbar-collapse navbar-main-collapse mini-nav">
            <ul className="nav navbar-nav">
              {/* Hidden li included to remove active class from about link when scrolled up past about section*/}
              <li className="hidden">
                <a href="#page-top" />
              </li>
              <li>
                <a href="/">
                  {props.strings["home_2"]} <span className="caret" />
                </a>
              </li>
              <li>
                <a href={`/about/${props.currentLanguageCode}`}>
                  {props.strings["about_2"]} <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href={`/about/${props.currentLanguageCode}`}>
                      {props.strings["about_us_2"]}
                    </a>
                  </li>

                  <li>
                    <a href={`/solutions/${props.currentLanguageCode}`}>
                      {props.strings["solutions_2"]}
                    </a>
                  </li>
                  <li>
                    <a href={`/fields/${props.currentLanguageCode}`}>
                      {props.strings["fields_2"]}
                    </a>
                  </li>
                  <li>
                    <a href={`/values/${props.currentLanguageCode}`}>
                      {props.strings["values_2"]}
                    </a>
                  </li>
                  <li>
                    <a href={`/gallery/${props.currentLanguageCode}`}>
                      {props.strings["gallery_2"]}
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a href={`/quality/${props.currentLanguageCode}`}>
                  {props.strings["qualitly"]} <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href={`/quality/${props.currentLanguageCode}`}>
                      {" "}
                      {props.strings["qualitly"]}
                    </a>
                  </li>
                  <li>
                    <a href={`/certificates/${props.currentLanguageCode}`}>
                      {props.strings["certificates_2"]}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/third_party_inspection/${props.currentLanguageCode}`}
                    >
                      {" "}
                      {props.strings["third_party_inception_2"]}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href={`/articles/${props.currentLanguageCode}`}>
                  {props.strings["articles_2"]}
                  <span className="caret" />
                </a>
              </li>
              <li>
                <a href={`/sea_water/${props.currentLanguageCode}`}>
                  {props.strings["induestries_2"]}
                  <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href={`/water_supply/${props.currentLanguageCode}`}>
                      {" "}
                      {props.strings["home_text_1_1"]}
                    </a>
                  </li>
                  <li>
                    <a href={`/sea_water/${props.currentLanguageCode}`}>
                      {props.strings["sea_water_2"]}
                    </a>
                  </li>
                  <li>
                    <a href={`/waste_water/${props.currentLanguageCode}`}>
                      {props.strings["waste_water_2"]}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="nav navbar-nav pull-right">
              {" "}
              <li>
                <a href={`/categories_product/${props.currentLanguageCode}`}>
                  {props.strings["products_2"]} <span className="caret" />
                </a>
              </li>
              <li>
                <a href={`/contact/${props.currentLanguageCode}`}>
                  {props.strings["contact_2"]}
                  <span className="caret" />
                </a>
              </li>
              <li>
                <a href="#">
                  <nav className="navbar-q-w">
                    <button type="button" className="btn-bars-w">
                      <a href="#"> {props.strings["get_quote"]}</a>
                    </button>
                    <div className="navbar-collapse-q-w">
                      <span className="btn-close-w mt-5">
                        <img
                          src="/assets/img/close.png"
                          alt="denz water technologies "
                        ></img>
                      </span>
                      <div className="col-md-12 mt-5">
                        {/* Map Section*/}
                        <img
                          src="/assets/img/denz_logo_animation.gif"
                          alt="denz water technologies "
                          style={{ height: "5vh" }}
                        />

                        <p>{props.strings["Lets"]}</p>
                        <form
                          ref={form}
                          onSubmit={sendEmail}
                          id="contactForm"
                          name="sentMessage"
                          noValidate
                        >
                          <div className="col-lg-12 control-group">
                            <div className="form-group floating-label-form-group controls">
                              <label
                                className="sr-only control-label"
                                htmlFor="name"
                              >
                                {props.strings["name"]}
                              </label>
                              <input
                                className="form-control input-lg-2"
                                id="name"
                                name="name"
                                type="text"
                                placeholder={props.strings["name"]}
                                required
                                data-validation-required-message="Please enter name"
                              />
                              <span className="help-block text-danger" />
                            </div>
                          </div>
                          <div className="col-lg-12 control-group">
                            <div className="form-group floating-label-form-group controls">
                              <label
                                className="sr-only control-label"
                                htmlFor="tel"
                              >
                                {props.strings["tel"]}
                              </label>
                              <input
                                className="form-control input-lg-2"
                                id="tel"
                                name="tel"
                                type="tel"
                                placeholder={props.strings["tel"]}
                                required
                                data-validation-required-message="Please enter telephone"
                              />
                              <span className="help-block text-danger" />
                            </div>
                          </div>

                          <div className="col-lg-12 control-group">
                            <div className="form-group floating-label-form-group controls">
                              <label
                                className="sr-only control-label"
                                htmlFor="company"
                              >
                                {props.strings["company"]}
                              </label>
                              <input
                                className="form-control input-lg-2"
                                id="company"
                                type="text"
                                name="company"
                                placeholder={props.strings["company"]}
                                required
                                data-validation-required-message="Please enter company"
                              />
                              <span className="help-block text-danger" />
                            </div>
                          </div>
                          <div className="col-lg-12 control-group">
                            <div className="form-group floating-label-form-group controls">
                              <label
                                className="sr-only control-label"
                                htmlFor="email"
                              >
                                {props.strings["email"]}
                              </label>
                              <input
                                className="form-control input-lg-2"
                                id="email"
                                type="email"
                                name="email"
                                placeholder={props.strings["email"]}
                                required
                                data-validation-required-message="Please enter email"
                              />
                              <span className="help-block text-danger" />
                            </div>
                          </div>

                          <div className="control-group">
                            <div className="form-group floating-label-form-group controls">
                              <label
                                className="sr-only control-label"
                                htmlFor="message"
                              >
                                {props.strings["message"]}
                              </label>
                              <textarea
                                className="form-control input-lg-2"
                                id="message"
                                rows={4}
                                name="message"
                                placeholder={props.strings["message"]}
                                required
                                data-validation-required-message="Please enter a message."
                                aria-invalid="false"
                                defaultValue={""}
                              />
                              <span className="help-block text-danger" />
                            </div>
                          </div>
                          <div id="success" />
                          <div className="d-flex justify-content-center">
                            <ReCAPTCHA
                              sitekey="6LdcOPQjAAAAANfz-i0yOYwK9oXckVoC1IPOvanm"
                              onChange={onChange}
                              theme="light"
                              size="compact"
                            />
                          </div>
                          <div className="d-flex justify-content-center">
                            <button
                              className="btn btn-dark btn-lg"
                              type="submit"
                            >
                              {props.strings["send"]}
                            </button>
                          </div>

                          <ul className="list-inline no-pad mt-5 d-flex justify-content-center">
                            <li>
                              <a
                                href="https://www.instagram.com/denzwater/?igshid=NTdlMDg3MTY%3D"
                                target="_blank"
                              >
                                <i className="fab fa-instagram fa-fw fa-lg" />
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://www.linkedin.com/company/denzwater/"
                                target="_blank"
                              >
                                <i className="fab fab fa-linkedin-in fa-fw fa-lg" />
                              </a>
                            </li>
                          </ul>
                        </form>
                      </div>
                    </div>
                  </nav>

                  <span className="caret" />
                </a>
              </li>
              <li className="visible-lg">&nbsp;&nbsp;&nbsp;&nbsp;</li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
Header2.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(Header2));
