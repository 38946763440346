import React, { useState, useEffect } from "react";
import "rc-pagination/assets/index.css";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLocation, useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { multilanguage, loadLanguages } from "redux-multilanguage";

// Import the category descriptions JSON
import categoryDesc from "../data/categoriesDesc.json";

const ProductPage = ({ products, strings, currentLanguageCode }) => {
  let params = useParams();

  const [collection, setCollection] = useState([]);
  const [resultLast, setResultLast] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    if (params) {
      let filterData = products.filter(
        (item) =>
          item.categories &&
          item.categories.toLowerCase() ==
          params.categories.replaceAll("-", " ").toLowerCase()
      );

      if (filterData.length > 0) {
        setCollection(filterData);
        setResultLast(filterData);
        document.title = filterData[0].categories + " | Denz Water Technologies";
        setCategoryName(filterData[0].categories);
      } else {
        setCollection(products);
        setResultLast(products);
      }

    } else {
      setCollection(products);
      setResultLast(products);
    }

    let arr = [];
    let seen = new Set(),
      result = products.filter(
        ({ categories }) => !seen.has(categories) && seen.add(categories)
      ),
      categories = result.map((key) => {
        var obj = {};

        obj = {
          checked: false,
          categories: key.categories,
          categories_fr: key.categories_fr,
          categories_ru: key.categories_ru,
          categories_ar: key.categories_ar,
          name_fr: key.name_fr,
          name: key.name,
          name_ru: key.name_ru,
          name_ar: key.name_ar,
        };

        arr.push(obj);
      });

    setCategories(arr);
  }, [products]);

  const onChange = (e) => {
    setCollection(products);

    if (e.target.value == "") {
      setResultLast(products);
      setCollection(products);
    } else {
      const val = e.target.value;
      const query = val.toLowerCase();
      const data = products.filter(
        (item) => item.name.toLowerCase().indexOf(query) > -1
      );
      setResultLast(data);
      setCollection(data);
    }
    setSearchName(e.target.value);
  };
  const metaDescription =
  resultLast.length > 0
    ? (() => {
        const categoryText = currentLanguageCode === "en" ? "You can find the products you are looking for in the " :
                             currentLanguageCode === "fr" ? "Vous pouvez trouver les produits que vous recherchez dans la " :
                             currentLanguageCode === "ru" ? "Вы можете найти товары, которые ищете, в " :
                             currentLanguageCode === "ar" ? "يمكنك العثور على المنتجات التي تبحث عنها في " :
                             currentLanguageCode === "tr" ? "Aradığınız ürünleri bu sayfada " : "-";
        const category = resultLast[0].categories; 
        return categoryText + category + " category on this page.";
      })()
    : "-";

  const langUrl =

    currentLanguageCode === "en"
      ? "en"
      : currentLanguageCode === "fr"
        ? "fr"
        : currentLanguageCode === "ru"
          ? "ru"
          : currentLanguageCode === "ar"
            ? "ar"
            : currentLanguageCode === "tr"
              ? "tr"
              : "-";

  // Get the description based on the category name
  const categoryDescription = categoryDesc[categoryName]?.description || "";
  const getCategoryDescription = (categoryName, currentLanguageCode) => {
    const categoryData = categoryDesc[categoryName];
  
    return !categoryData ? "" :
           currentLanguageCode === "en" ? categoryData.description_en :
           currentLanguageCode === "fr" ? categoryData.description_fr :
           currentLanguageCode === "ru" ? categoryData.description_ru :
           currentLanguageCode === "ar" ? categoryData.description_ar : "";
  };
  
  
  return (
    <>
      <Helmet>
        <meta name="description" content={metaDescription} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:url"
          content={`https://www.denzwater.com/products/${categoryName.replaceAll(" ", "-").toLowerCase()}/${langUrl}`}
        />
        <link
          rel="canonical"
          href={`https://www.denzwater.com/products/${categoryName.replaceAll(" ", "-").toLowerCase()}/${langUrl}`}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header />

      <header className="intro introhalf">
        <div className="overlay2"></div>
        <div className="intro-body">
          <h1>{categoryName}</h1>
        </div>
      </header>

      <section className="section-small" id="shop">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row grid-pad">
                <div className="col-md-8 text-center">
                  <div className="container-fluid">
                    <div className="row">
                      <div className=" col-12 text-left">
                        <h4 className="no-pad">
                          {strings["showing"]} {resultLast.length}{" "}
                          {strings["of"]} {collection.length}{" "}
                          {strings["results"]}
                        </h4>
                    
                        
                        <p>{getCategoryDescription(categoryName, currentLanguageCode)}</p>
 
                      </div>
                    </div>
                    <div className="row">
                      {/* Product display */}
                      {products.length > 0 ? (
                        collection
                          .slice()
                          .sort((a, b) => a.code.localeCompare(b.code))
                          .map((key, index) => {
                            return (
                              <span key={index}>
                                {index % 3 === 0 && index !== 0 ? (
                                  <>
                                    <div className="clearfix"></div>
                                  </>
                                ) : null}
                                <div className="col-sm-4">
                                  <div className="shop-item">
                                    <a
                                      href={`/products_details/${key.name
                                        .replaceAll(" ", "-")
                                        .toLowerCase()}/${currentLanguageCode}`}
                                    >
                                      {key.img.length > 0 ? (
                                        <>
                                          <img
                                            className="img-responsive center-block"
                                            src={`https://restapi.denzwater.com/uploads/${key.img}`}
                                            alt={
                                              currentLanguageCode === "en"
                                                ? key.name
                                                : currentLanguageCode === "fr"
                                                  ? key.name_fr
                                                  : currentLanguageCode === "ru"
                                                    ? key.name_ru
                                                    : currentLanguageCode === "ar"
                                                      ? key.name_ar
                                                      : currentLanguageCode === "tr"
                                                        ? key.name
                                                        : "-"
                                            }
                                            title={
                                              currentLanguageCode === "en"
                                                ? key.name
                                                : currentLanguageCode === "fr"
                                                  ? key.name_fr
                                                  : currentLanguageCode === "ru"
                                                    ? key.name_ru
                                                    : currentLanguageCode === "ar"
                                                      ? key.name_ar
                                                      : currentLanguageCode === "tr"
                                                        ? key.name
                                                        : "-"
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            className="img-responsive center-block"
                                            src={key.img}
                                            alt={
                                              currentLanguageCode === "en"
                                                ? key.name
                                                : currentLanguageCode === "fr"
                                                  ? key.name_fr
                                                  : currentLanguageCode === "ru"
                                                    ? key.name_ru
                                                    : currentLanguageCode === "ar"
                                                      ? key.name_ar
                                                      : currentLanguageCode === "tr"
                                                        ? key.name
                                                        : "-"
                                            }
                                          />
                                        </>
                                      )}
                                    </a>
                                  </div>
                                  <a
                                    href={`/products_details/${key.name
                                      .replaceAll(" ", "-")
                                      .toLowerCase()}/${currentLanguageCode}`}
                                  >
                                    {" "}
                                    <h2 style={{fontSize:"1.5rem"}}>
                                      {currentLanguageCode === "en"
                                        ? key.name
                                        : currentLanguageCode === "fr"
                                          ? key.name_fr
                                          : currentLanguageCode === "ru"
                                            ? key.name_ru
                                            : currentLanguageCode === "ar"
                                              ? key.name_ar
                                              : currentLanguageCode === "tr"
                                                ? key.name
                                                : "-"}
                                    </h2>
                                    {key.subname ? <p>{key.subname}</p> : null}
                                  </a>
                                </div>
                              </span>
                            );
                          })
                      ) : (
                        <tr>
                          <th scope="row" colSpan={8} className="text-center">
                            {strings["not_product_found"]}
                          </th>
                        </tr>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-md-offset-1">
                  {/* Search form */}
                  <form className="form-inline subscribe-form">
                    <div className="input-group input-group-lg">
                      <input
                        className="form-control"
                        type="search"
                        name="search"
                        placeholder="Search..."
                        value={searchName}
                        onChange={(e) => onChange(e)}
                      />
                      <span className="input-group-btn ml-5">
                        <button
                          className="btn btn-info btn-product"
                          type="submit"
                          name="search"
                          onClick={(e) => {
                            e.preventDefault();
                            if (searchName == "") {
                              setResultLast(products);
                              setCollection(products);
                            } else {
                              const val = searchName;
                              const query = val.toLowerCase();
                              const data = collection.filter(
                                (item) =>
                                  item.name.toLowerCase().indexOf(query) > -1
                              );
                              setCollection(data);
                            }
                          }}
                        >
                          <i className="fa fa-search fa-lg" />
                        </button>
                      </span>
                    </div>
                  </form>
                  <hr />
                  <h4> {strings["categories"]}</h4>
                  <div className="mobile_">
                    <ul className="">
                      {/* Display categories */}
                      {categories.map((key, index) => {
                        return (
                          <li key={index}>
                            <a
                              href={
                                "/products/" +
                                key.categories
                                  .replaceAll(" ", "-")
                                  .toLowerCase() +
                                `/${currentLanguageCode}`
                              }
                              state={{ state: key.categories }}
                            >
                              {" "}
                              {currentLanguageCode === "en"
                                ? key.categories.slice(9)
                                : currentLanguageCode === "fr"
                                ? key.categories_fr
                                : currentLanguageCode === "ru"
                                ? key.categories_ru
                                : currentLanguageCode === "ar"
                                ? key.categories_ar
                                : currentLanguageCode === "tr"
                                ? key.categories.slice(9)
                                : "-"}
                            </a>
                          </li>
                        );
                      })}
                      <br />
                    </ul>
                  </div>
                  <hr />
                  <div className="row pricing text-center">
                    <div className="col-md-12 ">
                      <div className="panel panel-default ">
                        <img
                          src="/assets/img/denz_logo.svg"
                          style={{ height: "6vh", margin: "20px" }}
                          alt="denz water technologies "
                        ></img>
                        <div className="panel-heading ">
                          <h4>
                            <a
                              href="/assets/img/pdf/denz_brochure.pdf"
                              target={"_blank"}
                            >
                              {strings["product_portfolio"]}
                            </a>{" "}
                            <div className="d-flex justify-content-center">
                              <img
                                src="/assets/img/5.gif"
                                style={{ height: "7vh" }}
                                alt="denz water technologies "
                              ></img>
                            </div>
                            <h5 style={{ margin: "0" }}>
                              <a
                                href="/assets/img/pdf/denz_brochure.pdf"
                                target={"_blank"}
                              >
                                {
                                  strings[
                                  "Download_Our_Latest_Product_Brochure"
                                  ]
                                }
                              </a>{" "}
                            </h5>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

ProductPage.propTypes = {
  products: PropTypes.array,
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    products: state.productData.products,
  };
};

export default connect(mapStateToProps)(multilanguage(ProductPage));
